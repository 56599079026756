<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :label-col="{xs:8}" :wrapper-col="{xs:16}">
        <a-row :gutter="8">
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-item label="角色名称">
              <a-input placeholder="请输入" v-model="queryParam.name"/>
            </a-form-item>
          </a-col>
          <!-- <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-item label="状态">
              <a-select placeholder="请选择" v-model="queryParam.flagEnable" default-value="false">
                <a-select-option :value="''">全部</a-select-option>
                <a-select-option :value="false">正常</a-select-option>
                <a-select-option :value="true">禁用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <!-- <a-button type="primary" icon="plus" @click="$refs.modal.add()">新建</a-button>
      <a-dropdown v-if="selectedRowKeys.length > 0"> -->
      <a-button v-if="addEnable" type="primary" icon="plus" @click="$refs.modal.add()">新建</a-button>
      <a-dropdown v-if="removeEnable&&selectedRowKeys.length > 0">
        <!-- <a-menu slot="overlay">
          <a-menu-item key="1"><a-icon type="delete" />删除</a-menu-item>
          <a-menu-item key="2"><a-icon type="lock" />禁用</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px">
          批量操作 <a-icon type="down" />
        </a-button> -->
        <a-button type="danger" icon="delete" @click="delByIds(selectedRowKeys)">删除</a-button>
      </a-dropdown>
    </div>
    <a-table
      size="default"
      ref="table"
      rowKey="id"
      :rowSelection="{type: 'radio', selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
      :columns="columns"
      :data-source="tableData">
      <span slot="flagEnable" slot-scope="text,record">
        <a-switch v-model="record.flagEnable" @click="onChangeStatus(record)" />
      </span>
      <span slot="action" slot-scope="text, record">
        <div v-if="!record.flagAllPermission">
          <a v-if="editEnabel" @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a v-if="editEnabel" @click="handleScope(record)">数据权限</a>
          <a-divider type="vertical" />
          <a v-if="removeEnable" @click="delByIds([record.id])">删除</a>
        </div>
      </span>
    </a-table>
    <role-modal ref="modal" @ok="getList()" />
    <role-scope-modal ref="scopemodal" @ok="getList()" />
  </a-card>
</template>

<script>
import { STable } from '@/components'
import RoleModal from './components/FirmTemplateEditModal.vue'
import RoleScopeModal from './components/RoleScopeModal.vue'
import pick from 'lodash.pick'
import { checkPermission } from '@/utils/permissions'
import { listFirmTemplate, delFirmTemplate} from './api/FirmTemplateApi'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0
}

export default {
  name: 'TableList',
  components: {
    STable,
    RoleModal,
    RoleScopeModal
  },
  data () {
    return {
      visible: false,
      permissions: [],
      // 查询参数
      queryParam: {},
      page: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: () => `共${this.page.total}条`,
            },
      // 表头
      columns: [
        {
          title: '权限名称',
          dataIndex: 'name'
        },
        {
          title: '权限说明',
          dataIndex: 'remark'
        },
        {
          title: '是否启用',
          dataIndex: 'flagEnable',
          scopedSlots: { customRender: 'flagEnable' }
        },
        {
          title: '操作',
          width: '200px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      tableData:[],
      selectedRowKeys: [],
      selectedRows: [],
      addEnable: checkPermission('firm:template:add'),
      editEnabel: checkPermission('firm:template:edit'),
      removeEnable: checkPermission('firm:template:remove')
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList() {
      listFirmTemplate ({
        current: this.page.current,
        pageSize: this.page.pageSize,
        ...this.queryParam
      }).then(res => {
        if (res.code === 200) {
          const { total, records } = res.body
          this.page.total = total
          this.tableData = records
        }
      }).finally(() => this.tableLoading = false)
    },
    onSelectChange (selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    handleAdd (parentId) {
      this.$refs.modal.add(parentId)
    },
    handleEdit (record) {
      this.$refs.modal.edit(record)
    },
    handleScope (record) {
      this.$refs.scopemodal.edit(record)
    },
    onChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    delByIds (ids) {
      const that = this
      this.$confirm({
        title: '警告',
        content: `真的要删除选中角色吗?`,
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          delFirmTemplate(ids).then(res => {
            if (res.code === 200) {
              that.$message.success(res.message)
              that.getList()
            } else {
              that.$message.error(res.message)
            }
            // const difference = new Set(this.selectedRowKeys.filter(x => !new Set(ids).has(x)))
            // this.selectedRowKeys = Array.from(difference)
            that.selectedRowKeys = []
          })
        },
        onCancel () {
          // console.log('Cancel')
        }
      })
    },
    onChangeStatus (record) {
      let status = record.flagEnable
      this.axios.get(`/api/firm/firm/firmTemplate/status/isEnable?templateId=${record.id}&flagEnable=${status}`).then(res => {
        if(res.code == 200) {
          this.$message.success(res.message)
          this.getList()
        }else {
          this.getList()
        }
      })
    }
  },
  watch: {
    /*
      'selectedRows': function (selectedRows) {
        this.needTotalList = this.needTotalList.map(item => {
          return {
            ...item,
            total: selectedRows.reduce( (sum, val) => {
              return sum + val[item.dataIndex]
            }, 0)
          }
        })
      }
      */
  }
}
</script>
